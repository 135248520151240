import React from 'react';
import Musics from '../constants/Musics';
import IMusic from '../interfaces/IMusic';

const RenderTile = (music: IMusic) => (
  <a href={music.url} key={music.name}>
    <div className="music">
      <iframe title={music.title} frameBorder={0} allowFullScreen={true} width="560" height="315" src={music.url} />
      <div className="desc">
        <h2>{music.title}</h2>
        <p>{music.description}</p>
      </div>
    </div>
  </a>
);

const MusicList = () => {
  const listItems = Musics.map((music) => RenderTile(music));
  return (<div className="content">{listItems}</div>)
};

export default MusicList;