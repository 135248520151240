import React from 'react';
import EventList from '../components/EventList';
import '../css/calendar.scss';

const Calendar = () => 
(
    <div id="calendar">
    {/* <Header title="Calendar" /> */}
    <EventList />
    </div>
);

export default Calendar;
