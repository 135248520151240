import Calendar from '../pages/Calendar';
import Apps from '../pages/Apps';
import Home from '../pages/Home';
import Music from '../pages/Music';
import Reviews from '../pages/Reviews';
import Sports from '../pages/Sports';
import Articles from '../pages/Articles';
import Videos from '../pages/Videos';
import AboutMe from '../pages/Me';

const Routes = [
  { url: '/', exact: true, comp: Home },
  { url: '/calendar', exact: false, comp: Calendar },
  { url: '/music', exact: false, comp: Music },
  { url: '/sports', exact: false, comp: Sports },
  { url: '/reviews', exact: false, comp: Reviews },
  { url: '/apps', exact: false, comp: Apps },
  { url: '/videos', exact: false, comp: Videos },
  { url: '/articles', exact: false, comp: Articles },
  { url: '/me', exact: false, comp: AboutMe },
];

export default Routes;
