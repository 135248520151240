import React from 'react';
import Videos from '../constants/Videos';
import IVideo from '../interfaces/IVideo';

const RenderTile = (video: IVideo) => (
  <a href={video.url} key={video.name}>
    <div className="video">
      <iframe title={video.title} frameBorder={0} allowFullScreen={true} width="560" height="315" src={video.url} />
      <div className="desc">
        <h2>{video.title}</h2>
        <p>{video.description}</p>
      </div>
    </div>
  </a>
);

const VideoList = () => {
  const listItems = Videos.map((video) => RenderTile(video));
  return (<div className="content">{listItems}</div>)
};

export default VideoList;