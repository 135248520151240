const Calendar = {
  name: 'Calendar',
  type: 'calendar',
  url: 'calendar',
  img: [],
  more: {
    event: 'Nations League: Belgium - Denmark',
    location: 'Leuven',
    timing: 'November 18',
    day_num: '18',
    day_text: 'Wednesday',
  }
};
const Apps = {
  name: 'Apps',
  type: 'tiles',
  img: [
    {
      url: 'http://covid.usidistrib.com/logo192.png',
      alt: ''
    },
    {
      url: '/images/apps/mc.png',
      alt: ''
    },
    {
      url: '/images/apps/om.png',
      alt: ''
    },
    {
      url: '/images/apps/mm.png',
      alt: ''
    },
  ],
  url: 'apps',
  more: undefined
};
const Music = {
  name: 'Music',
  type: 'icon',
  url: 'music',
  img: [{
    url: '/images/music.png',
    alt: 'Pic of a headset',
  }],
  more: undefined,
};
const Videos = {
  name: 'Videos',
  type: 'icon',
  url: 'videos',
  img: [{
    url: '/images/videos.png',
    alt: 'Pic of a headset',
  }],
  more: undefined
};
const Sports = {
  name: 'Sports',
  type: 'icon',
  url: 'sports',
  img: [{
    url: '/images/sports.png',
    alt: 'Pic of a headset',
  }],
  more: undefined
};
const AboutMe = {
  name: 'About Me',
  type: 'pic',
  url: 'me',
  img: [{
    url: '/images/photo_jm_zoom.jpg',
    alt: 'Pic of a headset',
  }],
  more: undefined
};
// const Reviews = {
//   name: 'Reviews',
//   type: 'review',
//   url: 'reviews',
//   img: [{
//     url: 'https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4AX4U?ver=e041&q=90&m=6&h=920&w=1920&b=%23FFFFFFFF&l=f&o=t&aim=true',
//     alt: 'Surface Duo',
//   }],
//   more: undefined
// };

const Links = [
  Calendar,
  Apps,
  // Reviews,
  Music,
  Videos,
  Sports,
  AboutMe,
];

export default Links;
