import React from 'react';
// import logo from './logo.svg';
import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './constants/Routes';
import IRoute from './interfaces/IRoute';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './css/blog.scss';

const App = () => {
  const RenderRoute = (route: IRoute) => (
    route.exact
      ? <Route path={route.url} exact component={route.comp} />
      : <Route path={route.url} component={route.comp} />
  );

  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          {Routes.map((route) => RenderRoute(route))}
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
