import React from 'react';
import SportList from '../components/SportList'
import '../css/sport.scss';

const Sports = () =>
  (
    <div id="sports">
      <SportList />
    </div>
  );

export default Sports;
