import React from 'react';
import IApp from '../interfaces/IApp';
import Apps from '../constants/Apps';

const RenderTile = (app: IApp) => (
  <a target="_blank noreferrer" href={app.url} key={app.name}>
    <div className="article section">
      <img src={app.img} alt={app.alt} />
      <h2>{app.title}</h2>
      <p className="date">{app.date}</p>
      <p className="desc">{app.description}</p>
      <p className="summary">{app.summary}</p>
    </div>
  </a>
);

const AppList = () => {
  const listItems = Apps.map((app:IApp) => RenderTile(app));
  return (<div id="sections">{listItems}</div>)
}

export default AppList;
