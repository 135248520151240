import React from 'react';
import '../css/me.scss';

const Me = () =>
  (
    <div id="me">
      {/* <!--https://fbcdn-profile-a.akamaihd.net/hprofile-ak-snc4/49517_530450931_831783283_n.jpg--> */}
      <p><img id="profil_pic" src="images/photo_jm_zoom.jpg" alt="Jérémie Melchior" /></p>
      <div id="infos">
        <p>Author: JayMelBE</p>
        <p>Name: Jérémie Melchior</p>
        <p>Nickname: Jay</p>
        <p>Location: Fleurus, Belgium</p>
        <p><a target="_blank noreferrer" href="http://www.twitter.com/jaymelbe"><img src="images/twitter.png" alt="logo twitter" />Jaymelbe</a></p>
        <p><a target="_blank noreferrer" href="http://www.facebook.com/jaymelbe"><img src="images/facebook.png" alt="logo facebook" />facebook.com/jaymelbe</a></p>
      </div>
      <div id="actions">
        <p>Who am I? An engineer in Computing Science who loves sports, music, and so many things!</p>
        <p>I am a Ph.D. in University catholique de Louvain (Belgium) since January 2016.</p>
        {/* <p>There is a sub-site about Windows 8 <a href="win8">Consumer Preview (in French)</a></p> */}
      </div>

      <div id="footer">
      </div>
    </div>
  );

export default Me;
