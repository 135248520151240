import React from 'react';
import { Link } from 'react-router-dom';
import Links from '../constants/Links';
import ILink from '../interfaces/ILink';

interface Props {
  links: ILink[];
};

const LinkList = (props: Props) => {
  const links = props.links;
  const listItems = links.map((link) => RenderTile(link));
  return (<ul id="tiles">{listItems}</ul>)
};

const RenderTile = (link: ILink) => {
  switch (link.type) {
    case "icon":
      return RenderIcon(link);
    case "calendar":
      return RenderCalendar(link);
    case "tiles":
      return RenderTiles(link);
    case "review":
    case "pic":
      return RenderPic(link);
    default:
      return (<li />)
  }
};

const RenderTiles = (link: ILink) => {
  return (
    <Link to={link.url}>
      <li key={link.name}>
        <div className="pic_full" id="apps">
          <img src={link.img[0].url} alt={link.img[0].alt} />
          <img src={link.img[1].url} alt={link.img[1].alt} />
          <img src={link.img[2].url} alt={link.img[2].alt} />
          <img src={link.img[3].url} alt={link.img[3].alt} />
        </div>
        <span className="tile_title">{link.name}</span>
      </li>
    </Link>
  )
};

const RenderCalendar = (link: ILink) => {
  return (
    <Link to={link.url} className="full">
      <li key={link.name}>
        <div id="cal">
          {/* className="collapsible" */}
          <div id="cal-left" >
            <p id="event" className="cal_small">{link.more.event}</p>
            <p id="location" className="cal_small">{link.more.location}</p>
            <p id="timing" className="cal_small">{link.more.timing}</p>
          </div>
          <div id="cal-right">
            <p id="day_num">{link.more.day_num}</p>
            <p id="day_text" className="cal_small">{link.more.day_text}</p>
          </div>
        </div>
        <span className="tile_title">{link.name}</span>
      </li>
    </Link>
  )
};

const RenderIcon = (link: ILink) => {
  return (
    <Link to={link.url}>
      <li key={link.name}>
        <div className="pic_full">
          <img src={link.img[0].url} alt={link.img[0].alt} />
        </div>
        <span className="tile_title">{link.name}</span>
      </li>
    </Link>
  )
};

const RenderPic = (link: ILink) => {
  return (
    <Link to={link.url}>
      <li key={link.name}>
        <div className="pic_180">
          <img width="140" src={link.img[0].url} alt={link.img[0].alt} />
        </div>
        <span className="tile_title">{link.name}</span>
      </li>
    </Link>
  )
};

const TileLayout = () => {
  return (
    <LinkList links={Links} />
  )
};

export default TileLayout;