const Musics = [
  {
    name: 'CarolineCosta-JeTaiMenti',
    url: 'http://www.youtube.com/embed/duepTTxh1ws',
    title: "Caroline Costa - Je t'ai menti (Kill For Lies) ",
    date: 'Évaluations : 1',
    description: "This music is from Caroline Costa, a young singer from France.",
  },
];

export default Musics;
