import React from 'react';
import IEvent from '../interfaces/IEvent';
import Events from '../constants/Events';

const RenderTile = (event: IEvent) => {
  return (
    <div className="section" key={event.name}>
      <a target="_blank noreferrer" href={event.url}>
        <div className="article">
          {event.img.length > 0 &&
            <div className="article__img">
              <img src={event.img[0].url} alt={event.img[0].alt} />
            </div>
          }
          <div className="article__title">
            <h2>{event.title}</h2>
            <p className="date">{event.date}</p>
          </div>
          <div className="article__desc">
          <p className="summary">{event.description}</p>
          <p className="click">Click on the tile to know more about this event</p>
          </div>
        </div>
      </a>
    </div>
  )
}

const EventList = () => {
  const listItems = Events.map((event) => RenderTile(event));
  return (<div className="sections">{listItems}</div>)
}

export default EventList;