import React from 'react';
import TileLayout from '../components/TileLayout';
import '../css/home.scss';

const Home = () =>
  (
    <div id="home">
      <TileLayout />
    </div>
  );

export default Home;