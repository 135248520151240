import React from 'react';
import '../css/footer.scss';

const Footer = () => (
  <footer>
    <hr />
    <div className="container footer-container">
      <p id="footer-container-left">
        <span>JayMelBE © 2004-2020 - </span>
        <a target="_blank noreferrer" href="http://usidistrib.com">
          Usidistrib
        </a>
      </p>
      <p id="footer-container-right">
        Dernière mise à jour : 18 Novembre 2020
      </p>
    </div>
  </footer>
);

export default Footer;
