import React from 'react';
import ISport from '../interfaces/ISport';
import Sports from '../constants/Sports';

const RenderTile = (sport: ISport) => {
  return (
    <div className="section" key={sport.name}>
      <a href={sport.url}>
        {sport.name !== 'Recap-JO-2012' && <p className="new">New</p> }
        <div className="article">
          {sport.img.length > 0 &&
            <div className="article__img">
              <img src={sport.img[0].url} alt={sport.img[0].alt} />
            </div>
          }
          <div className="article__title">
            <h2>{sport.title}</h2>
            <p className="date">{sport.date}</p>
          </div>
          <div className="article__desc">
            <p className="summary">{sport.description}</p>
            <p className="click">Cliquez sur la tuile pour en savoir plus</p>
          </div>
        </div>
      </a>
    </div>
  )
}

const SportList = () => {
  const listItems = Sports.map((sport) => RenderTile(sport));
  return (<div className="sections">{listItems}</div>)
}

export default SportList;