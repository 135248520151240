const Apps = [
  {
    name: 'Covid19',
    img: 'http://covid.usidistrib.com/logo192.png',
    // img: 'http://catalog.zune.net/v3.2/fr-BE/apps/ab4c35a5-d5a7-4937-ae5f-6487c9825dcd/primaryImage?width=240&height=240&resize=true',
    url: 'http://covid.usidistrib.com',
    title: 'Covid19 by Usidistrib',
    date: '',
    description: "Les chiffres du jour et les dernières tendances pour 4 grands facteurs : contaminations, hospitalisations, décès et l'écart par rapport à la semaine précédente.",
    summary: "Site web",
    alt: 'Covid19 by Usidistrib',
  },
  {
    name: 'MonChrono',
    img: 'http://blog.usidistrib.be/images/apps/mc.png',
    // img: 'http://catalog.zune.net/v3.2/fr-BE/apps/ab4c35a5-d5a7-4937-ae5f-6487c9825dcd/primaryImage?width=240&height=240&resize=true',
    url: 'http://www.windowsphone.com/fr-be/apps/ab4c35a5-d5a7-4937-ae5f-6487c9825dcd',
    title: 'Mon Chrono',
    date: 'Évaluations : 1',
    description: "Un chronomètre avec fonctions: Démarrer, Pause/Résume, Arrêter et Reset Qui propose également un affichage de l'heure de départ sur la Tuile (Tile).",
    summary: "Gratuite",
    alt: 'Image de Mon Chrono',
  },
  {
    name: 'OptiqueMuls',
    img: 'http://blog.usidistrib.be/images/apps/om.png',
    // img: 'http://catalog.zune.net/v3.2/fr-BE/apps/f45be035-1783-4af5-a2bd-13fec0261223/primaryImage?width=240&height=240&resize=true',
    url: 'http://www.windowsphone.com/fr-be/apps/f45be035-1783-4af5-a2bd-13fec0261223',
    title: 'Muls.be',
    date: 'Aucune evaluation',
    description: "Toutes les informations relatives au magasin Optique Muls à Sambreville (Belgique). Quelques photos, les heures d'ouvertures, ...",
    summary: "Gratuite",
    alt: 'Image de Muls.be',
  },
  {
    name: 'Minuteur',
    img: 'http://blog.usidistrib.be/images/apps/mm.png',
    // img: 'http://catalog.zune.net/v3.2/fr-BE/apps/74200b23-8fc2-4af9-8557-fe797ba24776/primaryImage?width=240&height=240&resize=true',
    url: 'http://www.windowsphone.com/fr-be/apps/74200b23-8fc2-4af9-8557-fe797ba24776',
    title: 'Minuteur',
    date: 'Aucune evaluation',
    description: "Cette application permet de créer des alarmes dans le style d'un minuteur: Pour choisir dans combien de temps Pour choisir sa description La dernière alarme ajoutée est affichée",
    summary: "Gratuite",
    alt: 'Image de Minuteur',
  },
]

export default Apps;
