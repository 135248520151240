import React from 'react';
import MusicList from '../components/MusicList';
import '../css/music.scss';

const Music = () =>
  (
    <div id="music">
      <MusicList />
    </div>
  );

export default Music;
