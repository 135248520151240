import React from 'react';
import AppList from '../components/AppList';
import '../css/apps.scss';

const Apps = () =>
  (
    <div id="apps">
      <AppList />
    </div>
  );

export default Apps;
