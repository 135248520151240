import React from 'react';
import IArticle from '../interfaces/IArticle';
import Articles from '../constants/Sports';
// import '../css/sport.scss';
import '../css/article.scss';

const RenderTile = (article: IArticle) => {
  return (
    <div id="sports">
      <h1 id="article-title">{article.title}</h1>
      <div className="article-section" key={article.name}>
        <div className="sport-article">
          {article.name !== 'Recap-JO-2012' && <p className="new">New</p>} 
          {article.img.length > 0 && <img src={article.img[0].url} alt={article.img[0].alt} />}
          {/* <h2>{article.title}</h2> */}
          <p className="date">{article.date}</p>
          <p className="summary">{article.description}</p>
          {article.name === '2020-11-18-Belgique-Nations-League-Summary' &&
            <div className="more">
              <p>
                Un match qui commence bien pour la Belgique, bien en place.
                Elle bloque bien le Danemark qui est incapable d'être dangereux et sur la première occasion, Youri Tielemans fait 1-0 dès la 3ème minute.
              </p>
              <p>
                La suite est plus calme avec une équipe danoise qui semble sous le choc. Mais un éclat d'Eriksen permet une action dangereuse qui fait mouche, c'est 1-1 après 17 minutes.
                Le Danermark retrouve son équipe agressive et prend doucement possession du jeu contre une équipe belge qui se méfie fortement de chaque possession danoise à présent.
              </p>
              <p>
                Une grosse occasion de chaque côté et un jeu assez serré des deux côtés et la mi-temps se profile sur un nul.
              </p>
              <br />
              <p>
                Début de la deuxième mi-temps, aucun changement a signalé. Sur le terrain, même physionomie que lors de la fin de la première mi-temps.
                Deux équipes qui jouent serré et qui ne laissent pas d'occasion à l'adversaire.
              </p>
              <p>
                Il faudra dès lors une petite faute, un coup franc rapidement joué par Tielemans, une passe en un temps de De Bruyne pour Lukaku qui malgré un bel arrêt de Schmeichel verra le ballon terminer sa course dans les filets.
                C'est 2-1 et tout est à refaire pour le Danemark qui doit à nouveau mettre 2 buts s'ils veulent se qualifier.
              </p>
              <p>
                La situation va empirer pour les danois qui suite à un superbe centre de Thorgan Hazard voient encore Lukaku trouver le chemin vers les filets.
                C'est 3-1 et la Belgique se rapproche de ce "Final Four" historique.
              </p>
              <p>
                En fin de match, ça s'accélère avec un but contre son camp de Nacer Chadli qui donne un ballon trop poussé à Courtois qui n'arrive pas à le contrôler et le ballon file dans le but. Un 3-2 surréaliste.
                Mais c'est sans compter sur un Thomas Foket qui vient de monter et envoie un ballon en transversal pour atteindre Kévin De Bruyne qui ne se laisse pas prier et fait 4-2.
              </p>
              <p>
                Les dernières minutes et secondes s'écoulent mais plus rien n'empêchera la Belgique d'atteindre le premier objectif de cette deuxième saison de Nations League.
                Deux minutes supplémentaires qui ne change rien dans ce match et la Belgique est officielement qualifiée pour les demi-finales de la Nations League.
              </p>
              <p>
                Demi-finales où la Belgique retrouvera la France, l'Espagne mais également l'Italie qui s'est qualifiée facilement contre la Bosnie-Herségovine.
              </p>
              <br />
              <p>
                Bravo les Diables Rouges !!!
              </p>
            </div>
          }
          {article.name === '2020-11-18-Belgique-Nations-League' &&
            <div className="more">
              <p>Rendez-vous avec l'histoire !</p>
              <br />
              <p>Après une longue série de victoire sous l'ère Martinez, la qualification pour la phase finale de cette 2ème Nations League semblait offert à la Belgique. Malheureusement, c'était avant cette défaite en Angleterre.</p>
              <p>Après s'être racheté Dimanche, en l'emportant à domicile contre l'Angleterre (2-0), la Belgique doit encore attendre le résultat de son match ce soir contre le Danemark pour se qualifier.
              Le calcul est cependant très simple... il ne faut pas perdre !
            </p>
              <p>Inutile de dire que les Diables Rouges vont avoir à coeur de montrer au pays mais aussi aux autres nations qu'ils font partie des favoris pour cette deuxième édition et qu'ils gardent l'objectif de la gagner.</p>
              <p>L'équipe belge sera plutôt classique avec une équipe semblable à celle qui à affronter l'Angleterre. Reste à voir quelles seront les surprises apportées par Martinez pour se défaire de cette équipe Danoise.</p>
              <p>Parmi les absents de ce soir, Eden Hazard touché par le Covid19. Il faudra faire sans... comme contre l'Angleterre. Il faut également rajouter les suspendus : Meunier et Witsel.</p>
              <p>Inutile de dire que les rôles de Thorgan Hazard et Kévin De Bruyne seront très important. Il en est de même pour Dries Mertens et Romelu Lukaku qui seront là pour dynamiter la défense danoise.</p>
              <p>Du côté danois par contre, il faudra compter sur un Eriksen en pleine possession de ses moyens pour mener son équipe à la victoire.</p>
              <p>Rendez-vous ce soir à 20h45 pour un des 2 pays qui va écrire une nouvelle page dans son histoire.</p>
            </div>
          }
          {article.name === 'Recap-JO-2012' &&
            <div className="more">
              <p>
                Commençons par le positif: les médailles.
          <br />
                {/* alt"Site web de Charline Van Snick" */}
            Dès l'entrée dans ces Jeux Olympiques, une judoka Belge s'est faite remarquée:
            <a href="https://sites.google.com/site/charlinevansnick/"> Charline Van Snick</a>.
            Elle obtient la médaille de Bronze après un joli parcours avec une seule défaite en demi-finale.
            Elle sera malheureusement la seule en Judo à obtenir une médaille.
            <br />
                {/* alt"Article de la RTBF sur la médaille d'argent de Lionel Cox" */}
              Ensuite, c'est autour de <a href="http://www.rtbf.be/sport/londres-2012/detail_une-medaille-extraordinaire-inattendue?id=7815804">Lionel Cox</a>,
            un amateur qui, au tir à la carabine couché 50m (60 balles) empoche la médaille d'argent.
            <br />
                {/* alt"Site web de Evi van Acker" */}
            Et finalement, c'est l'incroyable <a href="http://www.evivanacker.be/">Evi van Acker</a>
            qui décroche le Bronze au terme d'un très bon parcours en voile dans la catégorie Laser Radial.
          </p>
              <p>
                Plutôt que de parler des déceptions maintenant, analysons sport par sport les résultats.
                Regardons par ordre alphabétique des sports les résultats belges.
          </p>
              <p>
                Le tout premier sport est l'athlétisme. Des médailles nous voulions, des médailles nous n'en avons pas eu
                Outre la bonne performance de Hans Van Alphen au décathlon avec sa 4ème place au général et 5 très bons résultats (2-3-3-2-1).
                C'est malheureusement les autres qui vont décevoir.
                Tia Hellebaut ne réussira un saut qu'à 1m97 pour l'emmener à la 5ème place en saut en hauteur, le record de la saison mais pas de médaille.
                Elodie Ouedraogo, les frères Borlée, Michael Bultheel, Adrien Deghelt, Almensh Belete, Anne Zagré, Eline Berings et Svetlana Bolshakova n'iront malheureusement pas assez loin.
                Le relai 4x400m ne fera pas illusion ni dans les demi-finales ni dans la finale.
                Finalement, c'est Sara Aerts qui perdra tout espoir sur blessure.
          </p>
              <p>
                En aviron, il n'y avait que Tim Maeyens en Skiff. Mais que s'est-il passé pour Tim Maeyens?
                Premier aux éliminatoires et deuxième de sa série en 1/4, il semblait en forme.
                Malheureusement, il finira dernier en demi-finale et de la finale B.
          </p>
              <p>
                En Badmington et Canoe-Kayak, il n'y avait pas vraiment d'espoir et aucune finale n'est à signaler.
          </p>
              <p>
                En cyclisme, c'est surtout Tom Boonen et Philippe Gilbert qui était très attendu.
                Malheureusement ni eux ni d'autres ne viendront se mettre en avant.
                Jolien D'Hoore en omnium, l'équipe de poursuite par équipes et Gijs Van Hoecke ne seront jamais assez rapide.
                Le mountainbike et le BMX ne seront guère mieux pour la Belgique.
          </p>
              <p>
                En équitation, c'est la malchance de Philippe Le Jeune avec Vigo qui restera la grande déception.
                Les autres ont fait de beaux J.O. mais sans jamais mettre en danger les futurs champions olympiques.
          </p>
              <p>
                La gymnastique et l'haltérophilie n'allaient pas réserver de surprise.
                A noter tout de même que Jimmy Verbaeys s'en sortira très bien pour ses premiers J.O.
          </p>
              <p>
                Le Hockey sur gazon n'était pas là pour les médailles mais plutôt pour mettre en danger les favoris.
                En tombant dans la poule des grandes équipes, c'était très mal embarqué pour nos 2 équipes de Hockey.
                Seulement, l'équipe féminine terminera 11ème avec une très belle victoire lors du match des 11-12.
                C'est après un très bon premier tournoi sans jamais être ridicule qu'elles quitteront le tournoi.
                L'équipe masculine décrochera une superbe 5ème place en battant l'Espagne pour la lutte pour la 5-6ème place.
                S'ils n'étaient pas tomber dans la poule des deux finalistes, ils auraient peut-être pu accrocher la médaille de Bronze, dommage.
          </p>
              <p>
                En judo, la médaille est au bout pour Charline Van Snick mais malheureusement pas pour les autres.
                C'est Dirk Van Tichelt éliminé par l'Américain disqualifié pour dopage qui repartira avec le plus de regrets de ces J.O.
                Derrière, ce sont de beaux résultats mais pas suffisant avec notamment Elco van der Geest qui tombera contre trop fort.
          </p>
              <p>
                En natation, malgré quelques victoires en série, aucune finale n'était possible pour nos nageurs.
                Brian Ryckeman sera surement celui qui repartira avec la plus grande déception.
                Lui qui visait le Top3 ne finira que 16ème d'une course de 10km dont il n'aura jamais vraiment eu le bon tempo.
          </p>
              <p>
                En Tennis, c'est Kim Clijsters qui ira le plus loin en simple dame mais tombera contre une Maria Sharapova plus forte.
                Les autres n'ont jamais réussi à briller et le double Kim Clijsters/Olivier Rochus ne sera malheureusement pas retenu en double mixte.
          </p>
              <p>
                En Tennis de table et en Triathlon, il n'y aura pas de médaille. Jean-Michel Saive était tombé face à de très bons joueurs.
                En Triathlon, ni Katrien Verstuyft ni Simon De Cuyper ne pourront rivaliser. Ils étaient dans le groupe des outsiders mais il fallait être encore plus fort.
          </p>
              <p>
                C'est finalement, le Tir et la Voile qui apporteront les deux dernières médailles.
                Evi Van Acker aurait pu gagner le Laser Radial mais perdra des points précieux lors des 2 dernières journées.
                Lionel Cox, seul tireur et amateur, a réussi là où les autres Belges ont échoué. Une belle médaille d'argent.
          </p>
              <p>
                Nous nous retrouverons donc dans 4 ans à Rio au Brésil. Bravo les Belges, bravo la Belgique.
          </p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const ArticleList = () => {
  const listItems = Articles.map((article: IArticle) => RenderTile(article));
  return (<div className="sections">{listItems}</div>)
}

export default ArticleList;