const Events = [
  {
    name: '2020-11-18-Belgique-Nations-League',
    title: 'Belgique - Danemark en Nations League',
    img: [{
      url:'https://th.bing.com/th/id/OIP.PcyUMplST6KB6q_-7zgfagAAAA?pid=Api&rs=1',
      alt: "Ecusson de l'équipe belge de football",
    }],
    url: 'articles',
    date: '18 November 2020 - Diables Rouges',
    description: "Ce mercredi 18 Novembre 2020, la Belgique et le Danemark ont rendez-vous avec leur destin. Ils sont les seuls rescapés de ce groupe où figurait l'Angleterre. Celui qui remportera ce match ira en demi-finale dans la Nations League. L'avantage est aux Diables Rouges qui peuvent se satisfaire d'un match nul.",
  },  {
    name: 'DuoNovUpdate',
    img: [{
      url:'https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4AX4U?ver=e041&q=90&m=6&h=920&w=1920&b=%23FFFFFFFF&l=f&o=t&aim=true',
      alt: 'Surface Duo',
    }],
    url: 'https://support.microsoft.com/en-us/help/4578416/surface-duo',
    title: 'Surface Duo November update',
    date: 'On November 13, 2020 - support.Microsoft.com',
    description: 'Microsoft has made available its second update for the Surface Duo. The main improvement is over the camera that is now less terrible. Especially in low light.',
  },
  {
    name: 'CovidConfinement',
    img: [{
      url:'http://covid.usidistrib.com/logo192.png',
      alt: 'Covid19',
    }],
    url: 'http://covid.usidistrib.com',
    title: 'Covid19 - Reconfinement !!!',
    date: '30 Octobre 2020 - covid.usidistrib.com',
    description: "Ce 30 octobre, le Comité National de Sécurité (CNS) a pris de nouvelles mesures pour lutter contre la propagation du Coronavirus (COVID-19) en Belgique. Les magasins non-essentiels sont désormais fermés de même que les cinémas, les restaurants, les bars, ... et ce pour 1 mois et demi (jusque mi-décembre). Les chiffres continuent d'augmenter même si les tendances se veulent de plus en plus à la stabilisation.",
  },
  {
    name: 'DuoOctUpdate',
    img: [{
      url:'https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4AX4U?ver=e041&q=90&m=6&h=920&w=1920&b=%23FFFFFFFF&l=f&o=t&aim=true',
      alt: 'Surface Duo',
    }],
    url: 'https://support.microsoft.com/en-us/help/4578416/surface-duo',
    title: 'Surface Duo October update',
    date: 'On October 6, 2020 - support.Microsoft.com',
    description: 'Microsoft has finally made available its first update for the Surface Duo. No new feature in this update, but several improvements over the gestures, touch and performances. Also, there was a bug which led to dropping calls. This bug has been solved with this update.',
  },
  {
    name: 'Win8RTM',
    img: [],
    url: 'https://docs.microsoft.com/en-us/archive/blogs/jimoneil/windows-8-and-visual-studio-2012-completion-milestones',
    title: 'Windows 8 RTM and Visual Studio 2012 hit MSDN',
    date: 'On August 15, 2012 - MSDN.Microsoft.com',
    description: 'Before public release of Windows 8 on October 26, Windows 8 RTM will hit MSDN at the same time as Visual Studio 2012. It is time to get ready for general availability.',
  },
  {
    name: 'Win8GA',
    img: [{
      url: '/images/Win8_GA.jpg',
      alt: 'Pic of a headset',
    }],
    url: 'http://windowsteamblog.com/windows/b/bloggingwindows/archive/2012/07/18/windows-8-will-be-available-on.aspx',
    title: 'Windows 8: General Availability',
    date: 'On October 26, 2012 - Microsoft.com/windows',
    description: 'General availability of Windows 8. No more information has been given yet.',
  },
  {
    name: 'WP8',
    img: [{
      url: 'http://www.monwindowsphone.com/images/news/images/201208/Official%20Windows%20Phone%208%20logo.png',
      alt: 'Pic of a headset',
    }],
    url: 'http://www.ubergizmo.com/2012/07/windows-phone-8-to-be-released-in-october/',
    title: 'Windows Phone 8',
    date: 'On October 26, 2012 - WindowsPhone.com',
    description: 'Finally, Microsoft will release Windows Phone 8 along with Windows 8.',
  },
]

export default Events;
