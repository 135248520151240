import React from 'react';
import VideoList from '../components/VideoList';
import '../css/video.scss';

const Videos = () =>
  (
    <div id="videos">
      <VideoList />
    </div>
  );

export default Videos;
