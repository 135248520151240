const Sports = [
  {
    name: '2020-11-18-Belgique-Nations-League-Summary',
    title: 'Belgique 4 - 2 Danemark : le résumé',
    date: 'Le 18/11/20 - Nations League',
    description: "Nous en rêvions, ils l'ont fait ! La Belgique s'est qualifiée pour le 'Final Four' en battant le Danemark 4 buts à 2. Une demi-finale où la Belgique retrouvera soit la France, l'Italie ou l'Espagne.",
    img: [{
      url:'https://th.bing.com/th/id/OIP.PcyUMplST6KB6q_-7zgfagAAAA?pid=Api&rs=1',
      alt: "Ecusson de l'équipe belge de football",
    }],
    url: 'articles',
  },  
  {
    name: '2020-11-18-Belgique-Nations-League',
    title: 'Belgique - Danemark en Nations League',
    date: 'Le 18/11/20 - Nations League',
    description: "Ce mercredi 18 Novembre 2020, la Belgique et le Danemark ont rendez-vous avec leur destin. Ils sont les seuls rescapés de ce groupe où figurait l'Angleterre. Celui qui remportera ce match ira en demi-finale dans la Nations League. L'avantage est aux Diables Rouges qui peuvent se satisfaire d'un match nul.",
    img: [{
      url:'https://th.bing.com/th/id/OIP.PcyUMplST6KB6q_-7zgfagAAAA?pid=Api&rs=1',
      alt: "Ecusson de l'équipe belge de football",
    }],
    url: 'articles',
  },  
  {
    name: 'Recap-JO-2012',
    title: 'Récapitulatif des belges aux Jeux Olympiques 2012',
    date: 'Le 11/08/12 - J.O. London 2012',
    description: 'Nous voilà arrivés au terme des Jeux Olympiques de Londres. C\'est malheureusement avec un total de 3 médailles dont 1 en argent et 2 en bronzes pour la Belgique. Que faut-il penser? Déception, fierté, reconnaissance, contre-performance, surprise ?',
    img: [{
      url:'/images/belgium_jo.jpg',
      alt: 'Equipe belge au JO 2012',
    }],
    url: 'articles',
  },
]

export default Sports;
