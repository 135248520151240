const Videos = [
  {
    name: 'CarolineCosta-JeTaiMenti',
    url: 'http://www.youtube.com/embed/duepTTxh1ws',
    title: "Caroline Costa - Je t'ai menti (Kill For Lies) ",
    date: 'Évaluations : 1',
    description: "This music is from Caroline Costa, a young singer from France.",
  },
  {
    name: 'SimplePlan-Perfect',
    url: 'https://www.youtube.com/embed/RUi54JTgL5s',
    title: "Simple Plan - Perfect",
    date: 'Évaluations : 1',
    description: "One of my favourite music from Simple Plan. No one is perfect. And so am I.",
  },
  {
    name: 'Phelps-Freestyle-multiangle',
    url: 'https://www.youtube.com/embed/ax77_hHq9Dc',
    title: "Michael Phelps - Freestyle multi-angle camera",
    date: 'Évaluations : 1',
    description: "If you want to learn how to swim like Michael Phelps, this video is for you !",
  },
];

export default Videos;
